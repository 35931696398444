* {
  --font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --box-shadow: 3px 3px 5px #ccc;
}

@keyframes highlight {
  0% {
    background-color: #73bbd8;
  }
  100% {
    background-color: initial;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.2;
  }
}

@keyframes flyInTop {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

html, body {
  margin: 0;
  padding: 0;
  font-family: var(--font-family);
  background-color: #fff;
  color: #00446A;
}

h1, h2 {
  text-align: center;
  pointer-events: none;
}

section {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.App > svg {
  display: block;
  margin: 0 auto;
}

section#vendor-mapping {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
}

section#estimated-import-costs,
section#freight-charge-gaps,
section#approvals-and-replacement-costs {
  flex-direction: column;
  align-items: flex-start;
  gap: 4rem;
  width: 80%;
  max-width: 1400px;
  margin: 0 auto;
}

section#estimated-import-costs > div:last-child,
section#freight-charge-gaps > div:last-child,
section#approvals-and-replacement-costs > div:last-child {
  width: 100%;
}

select {
  background-color: transparent;
  font-family: var(--font-family);
  font-size: medium;
  width: 100%;
  border: 1px solid #ccc;
}

.App {
  display: flex;
  flex-direction: column;
  margin: 2rem;
}

.automation-toggle {
  align-self: flex-end;
  cursor: default;
  align-items: stretch;
  gap: 1rem;
  display: flex;
}

.automation-toggle > div {
  width: 40px;
  background-color: #aaa;
  border-radius: 1rem;
  display: flex;
  padding: 0.12rem;
  opacity: 0.5;
}

.automation-toggle > div > div {
  background-color: #fff;
  width: 45%;
  border-radius: 100%;
}

.highlight-row {
  animation: highlight 1s;
}

.data-grid {
  height: 100%;
  overflow: auto;
}

.data-grid-table {
  width: 100%;
  overflow: auto;
  border-collapse: collapse;
  table-layout: fixed;
  pointer-events: none;
}

.data-grid-row:nth-child(odd) {
  background-color: #f9f9f9;
}

.data-grid-cell {
  padding: 0.2rem;
}

.data-grid-header-cell:nth-child(1),
.data-grid-cell:nth-child(1) {
  width: 25px;
}

.data-grid-header-cell:nth-child(2),
.data-grid-cell:nth-child(2) {
  width: 75px;
}

.data-grid-header-cell:nth-child(3),
.data-grid-cell:nth-child(3) {
  width: 200px;
}

.data-grid-header-cell:nth-child(4),
.data-grid-cell:nth-child(4) {
  width: 150px;
}

.data-grid-header-cell:nth-child(5),
.data-grid-cell:nth-child(5) {
  width: 80px;
}

.data-grid-header-cell:nth-child(6),
.data-grid-cell:nth-child(6) {
  width: 100px;
}

.data-grid-header-cell:nth-child(7),
.data-grid-cell:nth-child(7) {
  width: 80px;
}

.data-grid-header-cell:nth-child(8),
.data-grid-cell:nth-child(8) {
  width: 100px;
}

.data-grid-cell:has(svg), .data-grid-cell:has(select) {
  pointer-events: initial;
}

.data-grid-cell svg {
  vertical-align: text-bottom;
  padding-left: 5px;
}

.data-grid-editable-cell {
  cursor: pointer;
  position: relative;
  z-index: 0;
}

.data-grid-cell-loading {
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(1px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.data-grid-header-row {
  background-color: #ccc;
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 1;
}

.data-grid-header-cell {
  text-align: left;
  padding: 0.5rem;
}

.maintenance-history td {
  padding-right: 1rem;
}

.maintenance-history-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.modal-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  opacity: 0.2;
  z-index: 10;
  animation: fadeIn 0.3s;
}

.modal-body {
  padding: 1rem;
}

.modal-dialog {
  background-color: #fff;
  min-height: max-content;
  min-width: 300px;
  z-index: 11;
  box-shadow: 3px 3px 5px #999;
  animation: flyInTop 0.5s;
  cursor: default;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
}

.modal-header h3 {
  margin: 0;
}

.modal-header svg:last-of-type {
  cursor: pointer;
  margin-left: auto;
}

.modal-footer:has(button) {
  padding: 0;
  display: flex;
}

.modal-footer button {
  width: 100%;
  background-color: #fff;
  border: none;
  border-top: 1px solid #eee;
  padding: 0.5rem;
  cursor: pointer;
  transition-duration: 150ms;
  font-weight: bold;
}

.modal-footer button:hover {
  background-color: #eee;
}

.modal-footer button:first-child {
  border-right: 1px solid #eee;
}

.modal-success {
  text-align: center;
  background-color: #00446A;
  color: #fff;
}

.modal-success h1 {
  margin: 2rem;
}

.nav-menu-container {
  display: flex;
  align-items: flex-start;
  height: 25px;
  margin-bottom: 2rem;
  color: #00446A;
}

.nav-menu-container > a svg {
  position: absolute;
  top: -20px;
  left: 5rem;
}

.nav-menu-icon {
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 2;
}

.nav-menu {
  position: relative;
  top: 50px;
  left: -50px;
  z-index: 2;
  width: max-content;
  display: flex;
  flex-direction: column;
  transition-duration: 200ms;
  box-shadow: var(--box-shadow);
  border: 1px solid #ccc;
}

.nav-menu button {
  background-color: #fff;
  border: none;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  width: 100%;
  text-align: left;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
}

.nav-menu button:hover {
  background-color: #ccc;
}

.no-access {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
}

.overview-section {
  box-shadow: var(--box-shadow);
  padding: 1rem 2rem;
  flex-grow: 1;
  max-width: 40%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  border: 1px solid #ccc
}

.overview-section a {
  text-decoration: unset;
  color: unset;
}

.overview-section > a {
  max-width: 45%;
  text-align: center;
}

.overview-section h2 {
  width: 100%;
}

.overview-section-process {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.overview-section-process:hover {
  background-color: #f9f9f9;
}

.overview-section-process .status-indicator span {
  color: #00a0dd;
}

.overview-status {
  max-width: max-content;
}

.process-button, .process-filepicker label {
  padding: 0.5rem;
  background-color: #00446A;
  box-shadow: var(--box-shadow);
  border: none;
  color: #fff;
  margin: 1rem 0;
  transition-duration: 200ms;
  cursor: pointer;
  font-weight: bolder;
}

.process-button:hover {
  box-shadow: 3px 3px 8px #aaa;
}

.process-button:active {
  box-shadow: var(--box-shadow);
}

.process-button:disabled {
  background-color: #808080;
  color: #a9a9a9;
  box-shadow: none;
  cursor: default;
}

.process-button-container {
  display: flex;
  gap: 2rem;
}

.process-button-overview-container {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  cursor: default;
}

.process-button-overview-container > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0rem;
}

.process-button-overview-container > div > span {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.process-checkbox {
  border: none;
  display: flex;
  gap: 0.4rem;
  cursor: pointer;
  align-items: center;
  color: #00446A;
  position: relative;
}

.process-checkbox.disabled {
  opacity: 0.4;
  color: #999;
  cursor: default;
}

.process-checkbox:hover .checkmark {
  background-color: #ddd;
}

.process-checkbox input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.process-checkbox .checkmark {
  height: 15px;
  width: 15px;
  background-color: #eee;
  transition-duration: 150ms;
}

.process-checkbox input:checked ~ .checkmark {
  background-color: #999;
}

.process-checkbox .checkmark:after {
  content: '';
  position: absolute;
  display: none;
  border: solid #666;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  height: 10px;
  width: 5px;
  left: 4px;
}

.process-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.process-datepicker {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: flex-start;
  min-width: 300px;
  margin-bottom: 1rem;
}

.process-datepicker > label {
  cursor: pointer;
}

.process-datepicker > span {
  margin-right: auto;
}

.process-datepicker .react-datepicker {
  border-radius: 0;
}

.process-datepicker .react-datepicker-wrapper input {
  border-radius: 0;
  border: 1px solid #ccc;
  height: 25px;
  cursor: pointer;
}

.process-datepicker .react-datepicker__day {
  border-radius: 0!important;
}

.react-datepicker__day--selected, 
.react-datepicker__day--in-selecting-range, 
.react-datepicker__day--in-range {
  background-color: #00446A;
}

.process-filepicker {
  font-size: smaller;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  gap: 0.4rem;
}

.process-filepicker-buttons {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.process-filepicker-name {
  background-color: #eee;
  height: 50%;
  width: 300px;
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
}

.process-flow-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: stretch;
}

.process-flow-container svg {
  align-self: center;
}

.process-flow-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: default;
}

.process-flow-step.active span {
  color: #00a0dd;
}

.progress-bar {
  max-width: 400px;
  width: 100%;
  align-self: center;
  margin-top: -4rem;
}

.progress-bar-steps {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.progress-bar-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 4px solid #e0e0e0;
  color: #999;
  font-size: larger;
  font-weight: bolder;
  background-color: #fff;
  z-index: 1;
  cursor: pointer;
}

.progress-bar-circle.active {
  border-color: #00446A;
}

.progress {
  position: absolute;
  height: 4px;
  width: 100%;
  background-color: #e0e0e0;
  z-index: 0;
}

.progress .indicator {
  position: absolute;
  height: 100%;
  transition-duration: 1s;
  width: 0%;
  background-color: #00446A;
}

.search-field {
  display: flex;
  align-items: center;
}

.search-field input {
  background-color: transparent;
  line-height: 1.5rem;
  border: 1px solid #ccc;
}

.status-indicator {
  text-align: center;
}

.status-indicator span {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.status-indicator-error span {
  color: #cc2f2f!important;
  cursor: pointer;
}

.status-indicator-error:hover {
  color: #b42c2c;
}

.vendor-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
